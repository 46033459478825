import { Component, OnInit } from '@angular/core';
import { DBDataService } from '../services/db-data.service';
import { FacilityStatus } from '../models/facilityStatus.model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelPopupComponent } from '../model-popup/model-popup.component';

@Component({
    selector: 'app-remove-hospital',
    templateUrl: './remove-hospital.component.html',
    styleUrls: ['./remove-hospital.component.css']
})
export class RemoveHospitalComponent implements OnInit {

    hospitalList: Array<FacilityStatus> = [];
    hospitalCode: number;

    constructor(private dataService: DBDataService, public router: Router, private modalService: NgbModal) { }

    ngOnInit() {
        this.getHospitalList();
    }
    getHospitalList() {
        var region = localStorage.getItem("region");
        this.dataService
            .getHospitalList(true, region)
            .subscribe((data: any) => {
                this.hospitalList = data;
            });
    }
    removeHospital() {
        this.dataService
            .removeHospital(this.hospitalCode)
            .subscribe((data: any) => {
                const modalRef = this.modalService.open(ModelPopupComponent);
                if (data) {
                    modalRef.componentInstance.name = 'Hospital Removed successfully';
                    modalRef.componentInstance.headerValue = 'Success';
                }
                else {
                    modalRef.componentInstance.name = 'Error occurred while remove hospital';
                    modalRef.componentInstance.headerValue = 'Error';
                }
                this.getHospitalList();
            });
    }

    lnkAddNewHospital() {
        this.router.navigate(['addnewhospital'], { skipLocationChange: true });
    }
    lnkRemoveHospital() {
        this.router.navigate(['removehospital'], { skipLocationChange: true });
    }
    lnkUpdateBedAvailability() {
        this.router.navigate(['adminUpdatebedavailability'], { skipLocationChange: true });
    }
}
