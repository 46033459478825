import { Component, OnInit } from '@angular/core';
import { DBDataService } from '../services/db-data.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacilityStatus } from '../models/facilityStatus.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-noupdatereport',
  templateUrl: './noupdatereport.component.html',
  styleUrls: ['./noupdatereport.component.css']
})
export class NoupdatereportComponent implements OnInit {

  maxDate: any;
  datePickerConfig: any;
  endDateValue: Date;
  startDateValue: Date;
  region: string;
  code: number;
  hospitalList: Array<FacilityStatus> = [];

  reportServer: string;
  reportUrl: string;
  showParameters: string;
  parameters: any;
  language: string = "en-us";
  width: number = 100;
  height: number = 100;
  toolbar: string = "true";

  constructor(private dataService: DBDataService, public router: Router, private modalService: NgbModal) {
    this.maxDate = new Date();
    this.datePickerConfig = Object.assign({},
      {
        dateInputFormat: 'MM/DD/YYYY',
        showWeekNumbers: false,
        containerClass: 'theme-dark-blue'
      });
  }

  ngOnInit() {
    this.region = localStorage.getItem("region");;
    this.dataService
      .getAdminBedAvailability(this.region)
      .subscribe((data: any) => {
        this.hospitalList = data;
      });
  }

  btnGenerateReport_Click() {
    debugger;

    var d = new Date(this.startDateValue);
    var datestring1 = ("0" + (d.getMonth() + 1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2) + "/" + + d.getFullYear();
    //alert(datestring1);

    var d1 = new Date(this.endDateValue);
    var datestring2 = ("0" + (d1.getMonth() + 1)).slice(-2) + "/" + ("0" + d1.getDate()).slice(-2) + "/" + + d1.getFullYear();
    //alert(datestring2);

    //this.reportServer = 'https://reports.ieba.biz/ReportServer/';
    //this.reportUrl = 'Perinatal/UpdateReport';

    //this.showParameters = "true";
    this.parameters = {
      "hospitalCode": this.code,
      "start_date": datestring1,
      "end_date": datestring2,
      "region": this.region,
    };

    this.router.navigate(['hospital-local', {
      reportUrl: 'Perinatal/NoUpdateReport', hasParameters: "true", mode: "noUpdateReport", hospitalCode: this.code, startDate: datestring1, endDate: datestring2, region: this.region
    }], { skipLocationChange: true });
  }



}
