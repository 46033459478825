import { Component, OnInit } from '@angular/core';
import { DBDataService } from '../services/db-data.service';
import { FacilityStatus } from '../models/facilityStatus.model';
import { Router } from '@angular/router';
import { ModelPopupComponent } from '../model-popup/model-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-admin-update-bed-availability',
    templateUrl: './admin-update-bed-availability.component.html',
    styleUrls: ['./admin-update-bed-availability.component.css']
})
export class AdminUpdateBedAvailabilityComponent implements OnInit {
    hospitalList: Array<FacilityStatus> = [];
    region: string;
    searchText: string;
    constructor(private dataService: DBDataService, public router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    debugger;
        this.region = localStorage.getItem("region");;
        this.dataService
            .getAdminBedAvailability(this.region)
            .subscribe((data: any) => {
                this.hospitalList = data;
            });
    }

    saveChanges() {
        this.dataService
            .saveAdminBedAvailabilityChanges(this.hospitalList)
            .then((data: boolean) => {
                const modalRef = this.modalService.open(ModelPopupComponent);
                if (data) {
                    modalRef.componentInstance.name = 'Bed avilability updated successfully';
                    modalRef.componentInstance.headerValue = 'Success';
                }
                else {
                    modalRef.componentInstance.name = 'Error occurred while update bed avilability';
                    modalRef.componentInstance.headerValue = 'Error';
                }
            });
    }
    cancle() {
        this.dataService
            .getAdminBedAvailability(this.region)
            .subscribe((data: any) => {
                this.hospitalList = data;
            });
    }
    lnkAddNewHospital() {
        this.router.navigate(['addnewhospital'], { skipLocationChange: true });
    }
    lnkRemoveHospital() {
        this.router.navigate(['removehospital'], { skipLocationChange: true });
    }
    onHospitalClick(hospital: FacilityStatus) {
      this.router.navigate(['updatehospitalprofile', hospital.code ], { skipLocationChange: true });
    }
}
