import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FacilityStatus } from '../models/facilityStatus.model';
import { DBDataService } from '../services/db-data.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ModelPopupComponent } from '../model-popup/model-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-update-hospital-profile',
    templateUrl: './update-hospital-profile.component.html',
    styleUrls: ['./update-hospital-profile.component.css']
})
export class UpdateHospitalProfileComponent implements OnInit {

    route$: Subscription;
  hospital: FacilityStatus = {} as FacilityStatus;
  changepassword: boolean;
    code: number;
    hospitalValid: boolean = true;
    cityValid: boolean = true;
  isAdmin: boolean = false;

    constructor(private dataService: DBDataService, private modalService: NgbModal, private route: ActivatedRoute, public router: Router) { }

    ngOnInit() {
        this.route$ = this.route.params.subscribe(
            (params: Params) => {
                this.code = params["code"] != null ? Number(params["code"]) : 0;
            }
        );
        if (this.code > 0) {
          this.getHospitalDetails(this.code);
          this.isAdmin = localStorage.getItem("isAdmin") == "1" ? true : false;
        }
    }
    cancle() {
        this.getHospitalDetails(this.code);
    }
    getHospitalDetails(code: any) {
        this.dataService
            .getHospitalDetails(code)
            .subscribe((data: any) => {
                this.hospital = data;
            });
    }
    updateProfile() {
        debugger;
        this.dataService
            .updateHospitalProfile(this.hospital)
            .then((data: any) => {
                const modalRef = this.modalService.open(ModelPopupComponent);

                if (data) {
                    modalRef.componentInstance.name = 'Hospital profile update successfully';
                    modalRef.componentInstance.headerValue = 'Success';
                }
                else {
                    modalRef.componentInstance.name = 'Error occurred while update hospital profile';
                    modalRef.componentInstance.headerValue = 'Error';
                }
            });
    }
    lnkUpdateProfile() {
        this.router.navigate(['updatehospitalprofile', { code: this.code }], { skipLocationChange: true });
    }
    lnkUpdateBedAvailability() {
        this.router.navigate(['updatebedavailability', { code: this.code }], { skipLocationChange: true });
    }
}
