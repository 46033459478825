export const ApiDictionary = {

    getHospitalList: {
        url: 'GetHospitalList',
        params: null
    },
    validateUser: {
        url: 'ValidateUser',
        params: null
    },
    SaveNewHospital: {
        url: 'SaveNewHospital',
        params: null
    },
    getHospitalDetails: {
        url: 'GetHospitalDetails',
        params: null
    },
    updateHospitalDetails: {
        url: 'UpdateHospitalDetails',
        params: null
    },
    updateHospitalProfile: {
        url: 'UpdateHospitalProfile',
        params: null
    },
    removeHospital: {
        url: 'RemoveHospital',
        params: null
    },
    getAdminBedAvailability: {
        url: 'GetAdminBedAvailability',
        params: null
    },
    saveAdminBedAvailabilityChanges: {
        url: 'SaveAdminBedAvailabilityChanges',
        params: null
    },
    getAllHospitalDetails: {
        url: 'GetAllHospitalDetails',
        params: null
    },
    getAllBedAvailabilityByRegion: {
      url: 'GetAllBedAvailabilityByRegion',
      params: null
  },
  getCities: {
    url: 'GetCities',
    params: null
  },
  getRegions: {
    url: 'GetRegions',
    params: null
  },
  Search: {
    url: 'Search',
    params: null
  },
}
