import { Component, OnInit } from '@angular/core';
import { FacilityStatus } from '../models/facilityStatus.model';
import { DBDataService } from '../services/db-data.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelPopupComponent } from '../model-popup/model-popup.component';

@Component({
  selector: 'app-update-bed-availability',
  templateUrl: './update-bed-availability.component.html',
  styleUrls: ['./update-bed-availability.component.css']
})
export class UpdateBedAvailabilityComponent implements OnInit {

  route$: Subscription;
  hospital: FacilityStatus = {} as FacilityStatus;
  code: number;
  noupdates: boolean = false;
  constructor(private dataService: DBDataService, private modalService: NgbModal, private route: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.route$ = this.route.params.subscribe(
      (params: Params) => {
        this.code = params["code"] != null ? Number(params["code"]) : 0;
      }
    );
    if (this.code > 0) {
      this.getHospitalDetails(this.code);

    }
  }
  cancle() {
    this.getHospitalDetails(this.code);
  }
  getHospitalDetails(code: any) {
    this.dataService
      .getHospitalDetails(code)
      .subscribe((data: any) => {
        this.hospital = data;
      });
  }
  update() {
    debugger;
    this.hospital.noupdates = this.noupdates;
    this.dataService
      .updateHospitalDetails(this.hospital)
      .then((data: any) => {
        const modalRef = this.modalService.open(ModelPopupComponent);

        if (data) {
          modalRef.componentInstance.name = 'Hospital bed availability update successfully';
          modalRef.componentInstance.headerValue = 'Success';
        }
        else {
          modalRef.componentInstance.name = 'Error occurred while update hospital  bed availability';
          modalRef.componentInstance.headerValue = 'Error';
        }
      });
  }
  lnkUpdateProfile() {
    this.router.navigate(['updatehospitalprofile', { code: this.code }], { skipLocationChange: true });
  }
  lnkUpdateBedAvailability() {
    this.router.navigate(['updatebedavailability', { code: this.code }], { skipLocationChange: true });
  }
}
