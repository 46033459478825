import { Component, OnInit } from '@angular/core';
import { DBDataService } from '../services/db-data.service';
import { FacilityStatus } from '../models/facilityStatus.model';
import { Router } from '@angular/router';
import { ModelPopupComponent } from '../model-popup/model-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Region } from '../models/region.model';

@Component({
  selector: 'app-search.component',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {
  city: Array<string> = [];
  region: Array<Region> = [];
  hospitalList: Array<FacilityStatus> = [];

  cityValue: string;
  regionValue: string;
  typeValue: string;
  hospitalValue: string;

  strCity: string;
  strRegion: string;
  strType: string;
  strHospitalName: string;

  isVisible: boolean = false;

  constructor(private dataService: DBDataService, public router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.dataService
      .getCities()
      .subscribe((data: any) => {
        debugger;
        this.city = data;
      });
    this.dataService
      .getRegions()
      .subscribe((data: any) => {
        this.region = data;
      });
      this.typeValue = "0";
      this.cityValue = "0";
      this.regionValue = "0";
  }

  search() {

    if (this.cityValue != null && this.cityValue != undefined && this.cityValue != "") 
      this.strCity = this.cityValue.trim();
    else
      this.strCity = "0";

    if (this.regionValue != null && this.regionValue != undefined && this.regionValue != "") 
      this.strRegion = this.regionValue.trim();
    else
      this.strRegion = "0";

    if (this.typeValue != null && this.typeValue != undefined && this.typeValue != "") 
      this.strType = this.typeValue.trim();
    else
      this.strType = "0";

    if (this.hospitalValue != null && this.hospitalValue != undefined && this.hospitalValue != "") 
      this.strHospitalName = this.hospitalValue.trim();
    else
      this.strHospitalName = "0";

    this.dataService
      .Search(this.strCity, this.strRegion, this.strType, this.strHospitalName)
      .subscribe((data: any) => {
        debugger;
        this.hospitalList = data;
      });
    this.isVisible = true;
  }

}
