import { Component, OnInit, ViewChild } from '@angular/core';
import { FacilityStatus } from '../models/facilityStatus.model';
import { DBDataService } from '../services/db-data.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelPopupComponent } from '../model-popup/model-popup.component';

@Component({
    selector: 'app-add-new-hospital',
    templateUrl: './add-new-hospital.component.html',
    styleUrls: ['./add-new-hospital.component.css']
})
export class AddNewHospitalComponent implements OnInit {

    hospitalNameValid: boolean = true;
    cityValid: boolean = true;
    typeValid: boolean = true;
    passwordValid: boolean = true;
    confirmPwdValid: boolean = true;
    confirmPwdValid2: boolean = true;
    facilityStatusObject: FacilityStatus = {} as FacilityStatus;
    @ViewChild('content') content;
    constructor(private dataService: DBDataService, public router: Router, private modalService: NgbModal) { }

    ngOnInit() {

    }
    btnSave_Click() {
        if (this.validateHospitalData()) {
            this.dataService
                .SaveNewHospital(this.facilityStatusObject)
                .then((data: boolean) => {
                    const modalRef = this.modalService.open(ModelPopupComponent);
                    if (data) {
                        modalRef.componentInstance.name = 'Hospital added successfully';
                        modalRef.componentInstance.headerValue = 'Success';
                    }
                    else {
                        modalRef.componentInstance.name = 'Error occurred while saveing hospital';
                        modalRef.componentInstance.headerValue = 'Error';
                    }
                    this.facilityStatusObject = {} as FacilityStatus;
                });
        }
    }
    validateHospitalData(): boolean {
        let isValid = true;
        if (this.facilityStatusObject.facility_name == null || this.facilityStatusObject.facility_name == undefined) { this.hospitalNameValid = isValid = false }
        if (this.facilityStatusObject.city == null || this.facilityStatusObject.city == undefined) { this.cityValid = isValid = false }
        if (this.facilityStatusObject.type == null || this.facilityStatusObject.type == undefined) { this.typeValid = isValid = false }
        //if (this.facilityStatusObject.provider_info == null || this.facilityStatusObject.provider_info == undefined) { this.hospitalNameValid = isValid = false }
        if (this.facilityStatusObject.password == null || this.facilityStatusObject.password == undefined) { this.passwordValid = isValid = false }
        if (this.facilityStatusObject.confirmPassword == null || this.facilityStatusObject.confirmPassword == undefined) { this.confirmPwdValid = isValid = false }
        if (this.facilityStatusObject.password != null && this.facilityStatusObject.confirmPassword != null && this.facilityStatusObject.password != this.facilityStatusObject.confirmPassword) {
            this.confirmPwdValid2 = isValid = false;
        }
        return isValid;
    }

    lnkAddNewHospital() {
        this.router.navigate(['addnewhospital'], { skipLocationChange: true });
    }
    lnkRemoveHospital() {
        this.router.navigate(['removehospital'], { skipLocationChange: true });
    }
    lnkUpdateBedAvailability() {
        this.router.navigate(['adminUpdatebedavailability'], { skipLocationChange: true });
    }
    cancel() {
        this.facilityStatusObject = {} as FacilityStatus;
    }
}


