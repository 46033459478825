import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpParams, HttpRequest, HttpClient } from '@angular/common/http';
import { ApiDictionary } from './api-dictionary';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DBDataService {

  public loginInfo: BehaviorSubject<number> = new BehaviorSubject(0);
  constructor(public apiService: ApiService, public _http: HttpClient) { }

  getHospitalList(include_admin: boolean, region:string) {
    let params = new HttpParams();
    const options = new HttpRequest<any>('GET', ApiDictionary.getHospitalList.url + "/" + include_admin + "/" + region, { params: params });
    return this.apiService.getDropdownData(options).map((res: any) => {
      return res;
    });
    }

    getAllHospitalDetails() {
        let params = new HttpParams();
        const options = new HttpRequest<any>('GET', ApiDictionary.getAllHospitalDetails.url);
        return this.apiService.getDropdownData(options).map((res: any) => {
            return res;
        });
    }
  getAllBedAvailabilityByRegion(region: string) {
        let params = new HttpParams();
      const options = new HttpRequest<any>('GET', ApiDictionary.getAllBedAvailabilityByRegion.url + "/" + region, { params: params });
        return this.apiService.getDropdownData(options).map((res: any) => {
          return res;
        });
    }

  validateUser(code: number, password: string): any {
    let params = new HttpParams();
    const options = new HttpRequest<any>('GET', ApiDictionary.validateUser.url + "/" + code + "/" + password, { params: params });
    return this.apiService.getDropdownData(options).map((res: any) => {
      return res;
    });
  }
  SaveNewHospital(facilityStatusObject: any) {
    return this.apiService.PostData(ApiDictionary.SaveNewHospital.url, facilityStatusObject).then((res: any) => {
      return res;
    });

  }
  getHospitalDetails(code:any) {
    
    let params = new HttpParams();
    const options = new HttpRequest<any>('GET', ApiDictionary.getHospitalDetails.url + "/" + code, { params: params });
    return this.apiService.getDropdownData(options).map((res: any) => {
      return res;
    });
  }
  updateHospitalDetails(hospital: any) {
    return this.apiService.PostData(ApiDictionary.updateHospitalDetails.url, hospital).then((res: any) => {
      return res;
    });

  }
  updateHospitalProfile(hospital: any) {
    return this.apiService.PostData(ApiDictionary.updateHospitalProfile.url, hospital).then((res: any) => {
      return res;
    });

  }
  removeHospital(hospitalCode: any) {
    let params = new HttpParams();
    const options = new HttpRequest<any>('GET', ApiDictionary.removeHospital.url + "/" + hospitalCode, { params: params });
    return this.apiService.getDropdownData(options).map((res: any) => {
      return res;
    });
  }
  getAdminBedAvailability(region: string) {
    
    let params = new HttpParams();
    const options = new HttpRequest<any>('GET', ApiDictionary.getAdminBedAvailability.url + "/" + region, { params: params });
    return this.apiService.getDropdownData(options).map((res: any) => {
      return res;
    });
  }
  saveAdminBedAvailabilityChanges(hospitalsList: any) {
    return this.apiService.PostData(ApiDictionary.saveAdminBedAvailabilityChanges.url, hospitalsList).then((res: any) => {
      return res;
    });
  }
  getCities() {
    const options = new HttpRequest<any>('GET', ApiDictionary.getCities.url);
    return this.apiService.getDropdownData(options).map((res: any) => {
      return res;
    });
  }
  getRegions() {
    let params = new HttpParams();
    const options = new HttpRequest<any>('GET', ApiDictionary.getRegions.url);
    return this.apiService.getDropdownData(options).map((res: any) => {
      return res;
    });
  }
  Search(city:string,region:string,type:string,hospitalName:string) {
    let params = new HttpParams();
    const options = new HttpRequest<any>('GET', ApiDictionary.Search.url + "/" + city + "/" + region + "/" + type + "/" + hospitalName, { params: params });
    return this.apiService.getDropdownData(options).map((res: any) => {
      return res;
    });
  }
}
