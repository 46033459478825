import { Component, OnInit } from '@angular/core';
import { DBDataService } from '../services/db-data.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
    isExpanded = false;
    isNotLogin: number;
    codevalue: string="";
    collapse() {
        this.isExpanded = false;
    }
    constructor(private service: DBDataService, private route: ActivatedRoute, public router: Router) {
        this.service.loginInfo.subscribe(newTitle => {
            this.isNotLogin = newTitle
            this.codevalue = localStorage.getItem("code");
            var admin = localStorage.getItem("isAdmin");
            if (admin != null) {
                this.isNotLogin = Number(admin);
            }
        });
    }
    ngOnInit() {
        this.codevalue = localStorage.getItem("code");
        var admin = localStorage.getItem("isAdmin");
        if (admin != null) {
            this.isNotLogin = Number(admin);
        }
    }
    logout() {
        localStorage.clear();
        this.service.loginInfo.next(0);
        this.router.navigate([''])
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}
