import { Component, OnInit } from '@angular/core';
import { DBDataService } from '../services/db-data.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacilityStatus } from '../models/facilityStatus.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-hospitallistexcelreport',
  templateUrl: './hospitallistexcelreport.component.html',
  styleUrls: ['./hospitallistexcelreport.component.css']
})
export class HospitallistexcelreportComponent implements OnInit {

  reportServer: string;
  reportUrl: string;
  showParameters: string;
  parameters: any;
  language: string = "en-us";
  width: number = 100;
  height: number = 100;
  toolbar: string = "true";

  constructor(private dataService: DBDataService, public router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.router.navigate(['hospital-local', {
      reportUrl: 'Perinatal/HospitalList', hasParameters: "false", mode: "excel"
    }], { skipLocationChange: true });
  }
}
