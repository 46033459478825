import { Component, OnInit } from '@angular/core';
import { DBDataService } from '../services/db-data.service';
import { Subscription } from 'rxjs';
import { FacilityStatus } from '../models/facilityStatus.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, Params } from '@angular/router';

@Component({
  selector: 'app-hospital-information',
  templateUrl: './hospital-information.component.html',
  styleUrls: ['./hospital-information.component.css']
})
export class HospitalInformationComponent implements OnInit {

  route$: Subscription;
  hospital: FacilityStatus = {} as FacilityStatus;
  code: number;
  hospitalValid: boolean = true;
  cityValid: boolean = true;

  constructor(private dataService: DBDataService, private modalService: NgbModal, private route: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.route$ = this.route.params.subscribe(
      (params: Params) => {
        this.code = params["code"] != null ? Number(params["code"]) : 0;
      }
    );
    if (this.code > 0) {
      this.getHospitalDetails(this.code);
    }
  }
  getHospitalDetails(code: any) {
    this.dataService
      .getHospitalDetails(code)
      .subscribe((data: any) => {
        this.hospital = data;
      });
  }
  cancle() {
    var item = localStorage.getItem("bedregion");
    this.router.navigate(['bedavilability', { region: item }]);

  }
}
