import { Component, OnInit } from '@angular/core';
import { DBDataService } from '../services/db-data.service';
import { FacilityStatus } from '../models/facilityStatus.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    hospitalList: Array<FacilityStatus> = [];
    code: number;
    password: string;
    isValidUser: boolean = true;

    constructor(private dataService: DBDataService, public router: Router) { }

    ngOnInit() {
        this.getHospitalList();
    }
    getHospitalList() {
        this.dataService
            .getHospitalList(true, "0")
            .subscribe((data: any) => {
                this.hospitalList = data;
            });
    }
    login() {
        this.dataService
            .validateUser(this.code, this.password)
            .subscribe((data: any) => {
                debugger;
                this.isValidUser = data;
                if (this.isValidUser) {
                    if (!this.hospitalList.find(x => x.code == this.code).is_admin) {
                        var id = this.hospitalList.find(x => x.code == this.code).region;
                        localStorage.setItem("region", id);
                        localStorage.setItem("isAdmin", "2");
                        localStorage.setItem("code", this.code.toString());
                        this.dataService.loginInfo.next(2);
                        this.router.navigate(['updatebedavailability', this.code]);
                    }
                    else {
                        var id = this.hospitalList.find(x => x.code == this.code).region;
                        localStorage.setItem("region", id);
                        localStorage.setItem("isAdmin", "1");
                        localStorage.setItem("code", this.code.toString());
                        this.dataService.loginInfo.next(1);
                        this.router.navigate(['adminUpdatebedavailability', this.code]);
                    }
                }
            });
    }
}
