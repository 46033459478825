import { Component } from '@angular/core';
import { DBDataService } from '../services/db-data.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent {
    constructor(private dataService: DBDataService, public router: Router) {
        var isAdmin = localStorage.getItem("isAdmin");
        if (isAdmin != null) {
            if (isAdmin == "1") {
                this.dataService.loginInfo.next(1);
                this.router.navigate(['adminUpdatebedavailability', localStorage.getItem("code")]);
            }
            else {
                this.dataService.loginInfo.next(2);
                this.router.navigate(['updatehospitalprofile', localStorage.getItem("code")]);
            }
        }

    }
}
