import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { NorthernCaliforniaComponent } from './northern-california/northern-california.component';
import { SouthernCaliforniaComponent } from './southern-california/southern-california.component';
import { ExternalReferenceComponent } from './external-reference/external-reference.component';
import { QualityImporvementComponent } from './quality-improvement/quality-improvement.component';
import { SearchComponent } from './search/search.component';
import { NeonatalTransportDataComponent } from './neonatal-transport-data/neonatal-transport-data.component';
import { FooterComponent } from './footer/footer.component';
import { HelpComponent } from './help/help.component';
import { LoginComponent } from './login/login.component';
import { ApiService } from './services/api.service';
import { DBDataService } from './services/db-data.service';
import { UpdateBedAvailabilityComponent } from './update-bed-availability/update-bed-availability.component';
import { AddNewHospitalComponent } from './add-new-hospital/add-new-hospital.component';
import { UpdateHospitalProfileComponent } from './update-hospital-profile/update-hospital-profile.component';
import { RemoveHospitalComponent } from './remove-hospital/remove-hospital.component';
import { AdminUpdateBedAvailabilityComponent } from './admin-update-bed-availability/admin-update-bed-availability.component';
import { DataTableModule } from "angular-6-datatable";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModelPopupComponent } from './model-popup/model-popup.component';
import { BedAvilabilityComponent } from './bed-avilability/bed-avilability.component';
import { HospitalInformationComponent } from './hospital-information/hospital-information.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReportViewerModule } from 'ngx-ssrs-reportviewer';
import { HospitalLocalComponent } from './hospital-local/hospital-local.component';
import { UpdatereportComponent } from './updatereport/updatereport.component';
import { NoupdatereportComponent } from './noupdatereport/noupdatereport.component';
import { HospitallistexcelreportComponent } from './hospitallistexcelreport/hospitallistexcelreport.component';
import { HospitallistpdfreportComponent } from './hospitallistpdfreport/hospitallistpdfreport.component';
import { CheckPasswordDirective } from './update-hospital-profile/check-password.directive';
@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    FooterComponent,
    HomeComponent,
    NorthernCaliforniaComponent,
    SouthernCaliforniaComponent,
    ExternalReferenceComponent,
    SearchComponent,
    QualityImporvementComponent,
    NeonatalTransportDataComponent,
    HelpComponent,
    LoginComponent,
    UpdateBedAvailabilityComponent,
    AddNewHospitalComponent,
    UpdateHospitalProfileComponent,
    RemoveHospitalComponent,
    AdminUpdateBedAvailabilityComponent,
    ModelPopupComponent,
    BedAvilabilityComponent,
    HospitalInformationComponent,
    HospitalLocalComponent,
    UpdatereportComponent,
    NoupdatereportComponent,
    HospitallistexcelreportComponent,
    HospitallistpdfreportComponent,
    CheckPasswordDirective
  ],

  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule, ReportViewerModule,
    FormsModule, BsDatepickerModule.forRoot(),
    DataTableModule, NgbModule.forRoot(),
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'northern-california', component: NorthernCaliforniaComponent },
      { path: 'southern-california', component: SouthernCaliforniaComponent },
      { path: 'external-reference', component: ExternalReferenceComponent },
      { path: 'search', component: SearchComponent },
      { path: 'quality-improvement', component: QualityImporvementComponent },
      { path: 'neonatal-transport-data', component: NeonatalTransportDataComponent },
      { path: 'help', component: HelpComponent },
      { path: 'login', component: LoginComponent },
      { path: 'updatebedavailability/:code', component: UpdateBedAvailabilityComponent },
      { path: 'addnewhospital', component: AddNewHospitalComponent, pathMatch: 'full' },
      { path: 'updatehospitalprofile/:code', component: UpdateHospitalProfileComponent },
      { path: 'removehospital', component: RemoveHospitalComponent },
      { path: 'adminUpdatebedavailability/:code', component: AdminUpdateBedAvailabilityComponent },
      { path: 'bedavilability', component: BedAvilabilityComponent },
      { path: 'hospitalprofile/:code', component: HospitalInformationComponent },
      { path: 'hospital-local', component: HospitalLocalComponent },
      { path: 'updatereport', component: UpdatereportComponent },
      { path: 'noupdatereport', component: NoupdatereportComponent },
      { path: 'hospitallistexcelreport', component: HospitallistexcelreportComponent },
      { path: 'hospitallistpdfreport', component: HospitallistpdfreportComponent },

    ])
  ],
  providers: [ApiService, DBDataService],
  bootstrap: [AppComponent],
  entryComponents: [ModelPopupComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }
