import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hospital-local',
  templateUrl: './hospital-local.component.html',
  styleUrls: ['./hospital-local.component.css']
})
export class HospitalLocalComponent implements OnInit {
  constructor(public router: Router, private route: ActivatedRoute) { }

  route$: Subscription;
  reportServer: string;
  reportUrl: string;
  mode: string;
  showParameters: string;
  parameters: any;
  language: string = "en-us";
  width: number = 100;
  height: number = 100;
  toolbar: string = "true";
  ngOnInit() {
    //reportServer: string = 'https://reports.ieba.biz/ReportServer/';
    //reportUrl: string = 'MFMS/Mono/E100 Journal By Program';
    this.reportServer = 'https://reports.ieba.biz/ReportServer/';
    //this.reportUrl = 'Perinatal/UpdateReport';

    this.showParameters = "false";



    this.route$ = this.route.params.subscribe(
      (params: Params) => {
        setTimeout(() => {
          this.mode = params["mode"];
          this.reportUrl = params["reportUrl"] != null ? params["reportUrl"] : '0';
          if (params["hasParameters"] == "true") {
            this.parameters = {
              "hospitalCode": params["hospitalCode"],
              "start_date": params["startDate"],
              "end_date": params["endDate"],
              "region": params["region"]
              //"SampleFloatParameter": "123.1234",
              //"SampleMultipleStringParameter": ["Parameter1", "Parameter2"]
            };
          }
        }, 500);
      }
    );


  }
  ngOnDestroy() {
    if (this.route$) this.route$.unsubscribe();
  }
  btnCancel_Click() {
    if (this.mode == "updateReport") {
      window.location.href = "updatereport";
    }
    else if (this.mode == "noUpdateReport") {
      window.location.href = "noupdateReport";
    }
    //else if (this.mode == "referal") {
    //  window.location.href = "agent/agentreferral";
    //}
    //else {
    //  window.location.href = "agent/claims";

    //}
  }
}
