import { Component, OnInit } from '@angular/core';
import { DBDataService } from '../services/db-data.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-bed-avilability',
    templateUrl: './bed-avilability.component.html',
    styleUrls: ['./bed-avilability.component.css']
})
export class BedAvilabilityComponent implements OnInit {

  constructor(private dataService: DBDataService, public router: Router, private route: ActivatedRoute, private modalService: NgbModal) { }
    intemidiatehospitalList = [];
    regionalList = [];
  communitylist = [];
  route$: Subscription;
  region: string;
  regionName: string;
  ngOnInit() {
    this.route$ = this.route.params.subscribe(
      (params: Params) => {

        this.region = params["region"];
        this.regionName = this.region == 'socal' ? 'Southern' : this.region == 'nocal' ? 'Northern' : 'Northern & Southern';
        localStorage.setItem("bedregion", this.region);
        if (this.region == 'both') {
          this.dataService
            .getAllHospitalDetails()
            .subscribe((data: any) => {
              debugger;
              var distinctThings = data.filter(
                (thing, i, arr) => arr.findIndex(t => t.type === thing.type) === i
              );
              for (var i = 0; i < distinctThings.length; i++) {
                if (distinctThings[i].type == 'INTERMEDIATE') {
                  this.intemidiatehospitalList = data.filter(x => x.type == 'INTERMEDIATE');
                }
                else if (distinctThings[i].type == 'REGIONAL') {
                  this.regionalList = data.filter(x => x.type == 'REGIONAL');
                }
                else {
                  this.communitylist = data.filter(x => x.type == distinctThings[i].type);
                }
              }
            });
        }
        else {
          this.dataService
            .getAllBedAvailabilityByRegion(this.region)
            .subscribe((data: any) => {
              var distinctThings = data.filter(
                (thing, i, arr) => arr.findIndex(t => t.type === thing.type) === i
              );
              for (var i = 0; i < distinctThings.length; i++) {
                if (distinctThings[i].type == 'INTERMEDIATE') {
                  this.intemidiatehospitalList = data.filter(x => x.type == 'INTERMEDIATE');
                }
                else if (distinctThings[i].type == 'REGIONAL') {
                  this.regionalList = data.filter(x => x.type == 'REGIONAL');
                }
                else {
                  this.communitylist = data.filter(x => x.type == distinctThings[i].type);
                }
              }
            });
        }
        
      }
    );
  }

}
